$(document).on("change", "#pre-dest-country", function () {
  updateCurrencyDropdown();
  $("#agents-table-body").empty();
});

$(document).on("change", "#pre-currency-select", function () {
  updateDeliveryTypeDropdown();
  $("#agents-table-body").empty();
});

$(document).on("change", "#pre-delivery-type", function () {
  updateProductDropdown();
  $("#agents-table-body").empty();
});

$("#select-all").on("change", function () {
  var isChecked = $(this).is(":checked");
  $("#agents-table-body input[type='checkbox']").prop("checked", isChecked);
});
$(document).on("change", ".sub-checkbox", function () {
  updateInputFieldsState();
});

$(document).on("change", "#pre-product", function () {
  let product = $(this).val();
  $("#hide-product").val(product);
  let destCountry = $("#pre-dest-country").val();
  let destCurrency = $("#pre-currency-select").val();
  let deliveryType = $("#pre-delivery-type").val();
  $.ajax({
    type: "GET",
    url: "/preferred_products",
    dataType: "json",
    data: {
      destination_country: destCountry,
      destination_currency: destCurrency,
      delivery_type: deliveryType,
      product: product,
    },
    success: function (response) {
      populateAgentsTable(response.agents);
    },
    error: function (xhr, status, error) {
      // Handle the error case
      console.error("AJAX Error: " + status + error);
    },
  });
});

function updateCurrencyDropdown() {
  var selectedCountry = $("#pre-dest-country").val();
  $("#hide-dest-country").val(selectedCountry);
  $.ajax({
    type: "GET",
    url: "/preferred_products",
    dataType: "json",
    data: { destination_country: selectedCountry },
    success: function (response) {
      let currencies = response.destination_currencies;
      updateDropdown("#pre-currency-select", currencies);
      updateDropdown("#pre-delivery-type", []);
      updateDropdown("#pre-product", []);
    },
    error: function (xhr, status, error) {
      // Handle the error case
      console.error("AJAX Error: " + status + error);
    },
  });
}

function updateDeliveryTypeDropdown() {
  var selectedCurrency = $("#pre-currency-select").val();
  $("#hide-dest-currency").val(selectedCurrency);
  $.ajax({
    type: "GET",
    url: "/preferred_products",
    dataType: "json",
    data: { destination_currency: selectedCurrency },
    success: function (response) {
      let deliveryTypes = response.delivery_types;
      updateDropdown("#pre-delivery-type", deliveryTypes);
      updateDropdown("#pre-product", []);
    },
    error: function (xhr, status, error) {
      // Handle the error case
      console.error("AJAX Error: " + status + error);
    },
  });
}

function updateProductDropdown() {
  var selectedDeliveryType = $("#pre-delivery-type").val();
  var selectedCurrency = $("#pre-currency-select").val();
  $("#hide-delivery-type").val(selectedDeliveryType);
  $.ajax({
    type: "GET",
    url: "/preferred_products",
    dataType: "json",
    data: {
      destination_country: $("#pre-dest-country").val(),
      destination_currency: selectedCurrency,
      delivery_type: selectedDeliveryType,
    },
    success: function (response) {
      let products = response.products;
      updateDropdown("#pre-product", products);
    },
    error: function (xhr, status, error) {
      // Handle the error case
      console.error("AJAX Error: " + status + error);
    },
  });
}

function updateDropdown(dropdownId, options) {
  var dropdown = $(dropdownId);
  dropdown.html("<option selected value>Please select</option>");

  if (options.length > 0) {
    for (var i = 0; i < options.length; i++) {
      dropdown.append(
        '<option value="' +
          options[i]["id"] +
          '">' +
          options[i]["name"] +
          "</option>"
      );
    }
  }
}

function populateAgentsTable(data) {
  $("#select-all").prop("checked", false);
  var tableBody = $("#agents-table-body");
  tableBody.empty();

  data.forEach(function (agent) {
    var row = $('<tr class="text-center"></tr>');
    var checkbox = $(
      `<input type="checkbox" name="agents[]" id="agent-checkbox" class="sub-checkbox" value=${agent.id}>`
    ).prop("checked", agent.preferred_product);
    var codeCell = $("<td></td>").text(agent.code);
    var nameCell = $("<td></td>").text(agent.name);

    row.append($("<td></td>").append(checkbox));
    row.append(codeCell);
    row.append(nameCell);
    tableBody.append(row);
  });
}

function updateInputFieldsState() {
  if ($(".sub-checkbox:checked").length === $(".sub-checkbox").length) {
    $("#select-all").prop("checked", true);
  } else {
    $("#select-all").prop("checked", false);
  }
}
